import React from "react";
import ReactDOM from "react-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { BrowserRouter } from "react-router-dom";
import App from "./App";

const theme = createMuiTheme({
  typography: {
    fontSize: 22,
    h2: {
      fontFamily: `'Bungee', cursive;`,
    },
    h3: {
      fontFamily: `'Bungee', cursive;`,
    },
    h5: {
      color: "#241909",
    },
  },
  palette: {
    type: "dark",
    primary: {
      dark: "#59bfff",
      main: "#8cd3ff",
    },
    background: {
      default: "#696969",
      paper: "#999999",
    },
  },
});

Object.assign(theme, {
  overrides: {
    MuiCssBaseline: {
      "@global": {
        ".public-DraftEditorPlaceholder-root": {
          color: "white",
        },
        ".public-DraftStyleDefault-ltr": {
          color: "white",
        },
        ".rdw-suggestion-dropdown": {
          color: "black",
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        fontFamily: `'Bangers', cursive`,
        fontSize: "36px",
        color: "white",
      },
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain="thinkfloat.us.auth0.com"
      clientId="Ln73U3mCovlnRm3wYv1JSJoizNzbLdSm"
      redirectUri={window.location.origin}
      audience="http://localhost:3005/"
      useRefreshTokens={true}
      scope=""
    >
      <BrowserRouter>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </MuiThemeProvider>
      </BrowserRouter>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
