import React from "react";
import { Button, Divider, Typography, Tooltip } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { TagType } from "../utils/types";
import useFetch from "../hooks/useApiWithAuth";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      flex: 3,
    },
    tagCard: {
      padding: theme.spacing(2),
      flex: 1,
      width: "100%",
    },
    circularSpinner: {
      display: "flex",
      justifyContent: "center",
      minHeight: "50vh",
      alignItems: "center",
    },
    tagTitle: {
      [theme.breakpoints.down("xs")]: {
        fontSize: "2rem",
      },
    },
  })
);

export const TagList = () => {
  const classes = useStyles();
  const { response, loading } = useFetch({
    url: "tags",
    useAuth: false,
  });

  return (
    <div className={classes.root}>
      <Divider />
      {loading ? (
        <div className={classes.circularSpinner}>
          <CircularProgress />
        </div>
      ) : (
        response.map((tag: TagType) => {
          return (
            <Tooltip key={tag.id} title={tag.tooltip} arrow placement="left">
              <Link style={{ textDecoration: "none" }} to={`/tag/${tag.id}`}>
                <Button color="primary" className={classes.tagCard}>
                  <Typography className={classes.tagTitle} variant="h3">
                    {tag.tag}
                  </Typography>
                </Button>
                <Divider />
              </Link>
            </Tooltip>
          );
        })
      )}
    </div>
  );
};

export default TagList;
