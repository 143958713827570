import React from "react";
import { Typography, Avatar, Grid } from "@material-ui/core";
import useFetch from "../hooks/useApiWithAuth";

const Profile = ({
  match: {
    params: { email },
  },
}: any) => {
  const { response: users } = useFetch({
    url: `getuserbyemail?email=${email}`,
  });

  return (
    <>
      {users.map((user: any) => (
        <Grid container key={user.username ? user.username : user.name}>
          <Grid direction="row" justify="flex-start" item xs={12}>
            <Avatar src={user.profilepic} />
            <Typography>{user.username ? user.username : user.name}</Typography>
          </Grid>
        </Grid>
      ))}
    </>
  );
};

export default Profile;
