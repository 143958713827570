import React from "react";
import { Button, ListItem, Typography, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

export const PinnedPosts = () => {
  const pinnedPosts = JSON.parse(localStorage.getItem("pinnedPosts") || "[]");
  return (
    <>
      <ListItem key="Pinned">
        <Typography>Pinned Posts</Typography>
      </ListItem>
      {pinnedPosts &&
        pinnedPosts.map(({ postId, author, tag }: any) => (
          <ListItem key={postId}>
            <IconButton
              onClick={() => {
                const pinnedPosts = JSON.parse(
                  localStorage.getItem("pinnedPosts") || "[]"
                );
                localStorage.setItem(
                  "pinnedPosts",
                  JSON.stringify(
                    pinnedPosts.filter(({ postId: thisPostId }: any) => {
                      return thisPostId !== postId;
                    })
                  )
                );
                window.location.reload();
              }}
              aria-label="delete"
            >
              <DeleteIcon />
            </IconButton>
            <Button style={{ width: "100%" }} href={`/post/${postId}`}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="body2">{tag}</Typography>
                <Typography variant="caption">{author}</Typography>
              </div>
            </Button>
          </ListItem>
        ))}
    </>
  );
};
