import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { API_ROOT } from "../utils/api-config";

export const useFetch = ({
  url = "",
  opts = {},
  immediate = true,
  useAuth = true,
}) => {
  const [response, setResponse] = useState([]);
  const [loading, setLoading] = useState(immediate);
  const [hasError, setHasError] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const execute = async (executeOpts = {}) => {
    setLoading(true);
    if (useAuth) {
      const accessToken = await getAccessTokenSilently({
        audience: `http://localhost:3005/`,
      });
      fetch(`${API_ROOT}/${url}`, {
        ...opts,
        ...executeOpts,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      })
        .then((res: any) => res.json())
        .then((res: any) => {
          setResponse(res);
          setLoading(false);
        })
        .catch(() => {
          setHasError(true);
          setLoading(false);
        });
    } else {
      fetch(`${API_ROOT}/${url}`, {
        ...opts,
        ...executeOpts,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res: any) => res.json())
        .then((res: any) => {
          setResponse(res);
          setLoading(false);
        })
        .catch(() => {
          setHasError(true);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (immediate) {
      execute();
    }
  }, [url]);
  return { response, loading, hasError, execute };
};

export default useFetch;
