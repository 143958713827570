import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    postSkeleton: {
      margin: `${theme.spacing(4)}px ${theme.spacing(48)}px`,
      [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(1),
      },
      [theme.breakpoints.down("md")]: {
        margin: `${theme.spacing(4)}px ${theme.spacing(24)}px`,
      },
    },
  })
);

export const PostsSkeleton = () => {
  const styles = useStyles();

  return (
    <>
      <Skeleton className={styles.postSkeleton} variant="rect" height={209} />
      <Skeleton className={styles.postSkeleton} variant="rect" height={209} />
      <Skeleton className={styles.postSkeleton} variant="rect" height={209} />
      <Skeleton className={styles.postSkeleton} variant="rect" height={209} />
    </>
  );
};

export default PostsSkeleton;
