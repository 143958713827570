import React, { createRef, RefObject, useEffect, useRef } from "react";
import AddComment from "../components/AddComment";
import Comments from "../components/Comments";
import Post from "../components/Post";
import { CommentType } from "../utils/types";
import useFetch from "../hooks/useApiWithAuth";
import { Typography } from "@material-ui/core";

export const PostWithComments = ({
  match: {
    params: { id, commentId = null },
  },
}: any) => {
  const { response: posts } = useFetch({
    url: `post?postId=${id}`,
    useAuth: false,
  });
  const { response: comments, execute: fetchComments, loading } = useFetch({
    url: `comments?postId=${id}`,
    useAuth: false,
  });

  const [elRefs, setElRefs] = React.useState<RefObject<HTMLDivElement>[]>();

  useEffect(() => {
    setElRefs((elRefs) =>
      Array(comments.length)
        .fill(0)
        .map((_, index) => (elRefs && elRefs[index]) || createRef())
    );
  }, [loading]);

  useEffect(() => {
    if (commentId !== null) {
      comments.map((value: CommentType, index) => {
        if (value.id == commentId && elRefs) {
          elRefs[index].current!.scrollIntoView({
            behavior: "auto",
            block: "start",
          });
        }
      });
    }
  }, [elRefs]);

  return (
    <div>
      <div style={{ width: "100%" }}>
        {posts.length > 0 && <Post isOnPostPage {...posts[0]} />}
        {posts.length > 0 &&
          comments
            .sort(
              (a: CommentType, b: CommentType) =>
                Date.parse(a.timestamp) - Date.parse(b.timestamp)
            )
            .map(
              (comment: CommentType, index) =>
                elRefs && (
                  <div ref={elRefs[index]} key={comment.id}>
                    <Comments
                      {...comment}
                      onCommentDelete={() => fetchComments()}
                    />
                  </div>
                )
            )}
        {posts.length > 0 && (
          <AddComment onCommentAdd={() => fetchComments()} postId={id} />
        )}
        {posts.length === 0 && !loading && (
          <Typography align="center">Post Not Found</Typography>
        )}
      </div>
    </div>
  );
};

export default PostWithComments;
