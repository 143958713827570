let backendHost;

const hostname = window && window.location && window.location.hostname;

console.log(hostname);

if (hostname === "thinkfloat.net") {
  backendHost = "https://thinkfloatapi.net";
} else {
  backendHost = "http://localhost:8080";
}

export const API_ROOT = `${backendHost}`;
