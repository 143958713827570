import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  AppBar,
  Hidden,
  IconButton,
  Toolbar,
  Typography,
  Link,
  Divider,
} from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import MenuIcon from "@material-ui/icons/Menu";
import Logo from "../assets/ryu_large.png";
import { Login } from "../components/Login";
import { Logout } from "../components/Logout";
import { PinnedPosts } from "../components/PinnedPosts";
import PageRouter from "../PageRouter";
import { MenuProfileWidget } from "./MenuProfileWidget";
import { useLocation } from "react-router-dom";

const drawerWidth = 300;

const links = [{ text: "Home", icon: <LocalOfferIcon />, link: "/" }];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    appBar: {
      marginLeft: drawerWidth,
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: drawerWidth,
      border: 0,
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    // necessary for content to be below app bar
    toolbar: {
      ...theme.mixins.toolbar,
      height: "113px",
    },
    content: {
      overflow: "auto",
      width: "100%",
      backgroundColor: theme.palette.background.default,
      ...theme.mixins.toolbar,
    },
    closeMenuButton: {
      marginRight: "auto",
      marginLeft: 0,
    },
    link: {
      textDecoration: "none",
      color: "white",
    },
    selectedPage: {
      backgroundColor: theme.palette.background.default,
    },
    logo: {
      width: "64px",
      marginLeft: theme.spacing(2),
    },
    divider: {
      marginLeft: theme.spacing(2),
    },
    title: {
      [theme.breakpoints.up("sm")]: {
        marginLeft: "370px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "2rem",
      },
    },
    login: {
      justifyContent: "flex-end",
    },
  })
);

export default function PermanentDrawerLeft() {
  const classes = useStyles();
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { isAuthenticated } = useAuth0();

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h2" className={classes.title}>
            Thinkfloat
          </Typography>
          <img src={Logo} alt="Logo" className={classes.logo} />
          <img src={Logo} alt="Logo" className={classes.logo} />
          <img src={Logo} alt="Logo" className={classes.logo} />
        </Toolbar>
      </AppBar>
      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <IconButton
            onClick={handleDrawerToggle}
            className={classes.closeMenuButton}
          >
            <CloseIcon />
          </IconButton>
          <MenuProfileWidget />
          <List style={{ display: "flex", flexDirection: "column" }}>
            {links.map(({ text, icon, link }) => (
              <Link
                underline="none"
                key={text}
                href={link}
                className={classes.link}
              >
                <ListItem
                  className={
                    link === location.pathname ? classes.selectedPage : ""
                  }
                  button
                  key={text}
                >
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              </Link>
            ))}
            {!isAuthenticated ? <Login /> : <Logout />}
            <PinnedPosts />
          </List>
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor="left"
        >
          <MenuProfileWidget />
          <List style={{ display: "flex", flexDirection: "column" }}>
            {links.map(({ text, icon, link }) => (
              <Link
                key={text}
                href={link}
                className={classes.link}
                underline="none"
              >
                <ListItem
                  className={
                    link === location.pathname ? classes.selectedPage : ""
                  }
                  button
                  key={text}
                >
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              </Link>
            ))}
            {!isAuthenticated ? <Login /> : <Logout />}
            <Divider />
            <PinnedPosts />
          </List>
        </Drawer>
      </Hidden>
      <div className={classes.content}>
        <div className={classes.toolbar} />
        <PageRouter />
      </div>
    </div>
  );
}
