import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import ArrowRight from "@material-ui/icons/ArrowRight";

export const Login = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <ListItem onClick={() => loginWithRedirect()} button key="Login">
      <ListItemIcon>
        <ArrowRight />
      </ListItemIcon>
      <ListItemText primary="Login" />
    </ListItem>
  );
};
