import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { PostType } from "../utils/types";
import useFetch from "../hooks/useApiWithAuth";
import draftToHtml from "draftjs-to-html";
import ReactHtmlParser from "react-html-parser";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    post: {
      margin: `${theme.spacing(4)}px ${theme.spacing(48)}px`,
      flex: 1,
      [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(1),
      },
      [theme.breakpoints.only("md")]: {
        margin: `${theme.spacing(4)}px ${theme.spacing(12)}px`,
      },
      [theme.breakpoints.only("lg")]: {
        margin: `${theme.spacing(4)}px ${theme.spacing(24)}px`,
      },
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
    avatar: {
      backgroundColor: theme.palette.primary.dark,
    },
  })
);

const Post = (post: PostType) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { user, isAuthenticated } = useAuth0();
  const history = useHistory();
  const { execute } = useFetch({
    url: "deletepost",
    immediate: false,
  });

  const formattedDateTime = () => {
    return new Date(post.timestamp).toLocaleString();
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleReport = () => {
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function transform(node: any) {
    // do not render any <span> tags
    console.log(node);
    if (node.type === "tag" && node.name === "img") {
      node.attribs.style = "height: 100%;width: 100%";
    }
  }

  return (
    <Card key={post.id} className={classes.post}>
      <>
        <CardHeader
          avatar={
            <Avatar
              aria-label="recipe"
              src={post.profilepic}
              className={classes.avatar}
            />
          }
          action={
            <>
              {post.isOnPostPage && (
                <IconButton onClick={handleClick} aria-label="settings">
                  <MoreVertIcon />
                </IconButton>
              )}
            </>
          }
          title={
            <>
              <strong>{post.fullname}</strong> posted in {post.tag}
            </>
          }
        />
        <CardContent style={{ overflowX: "auto" }}>
          {post.isOnPostPage && (
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {user.email !== post.email ? (
                <a href="mailto:admin@thinkfloat.net">
                  <MenuItem onClick={handleReport}>Report</MenuItem>
                </a>
              ) : (
                <MenuItem
                  onClick={() => {
                    execute({
                      method: "post",
                      body: JSON.stringify({
                        postId: post.id,
                      }),
                    });
                    history.push(`/`);
                  }}
                >
                  Delete
                </MenuItem>
              )}
              <MenuItem
                onClick={() => {
                  const pinnedPosts = JSON.parse(
                    localStorage.getItem("pinnedPosts") || "[]"
                  );
                  localStorage.setItem(
                    "pinnedPosts",
                    JSON.stringify([
                      {
                        postId: post.id,
                        author: post.fullname,
                        tag: post.tag,
                      },
                      ...pinnedPosts,
                    ])
                  );
                  window.location.reload();
                }}
              >
                Pin Post
              </MenuItem>
            </Menu>
          )}
          {post.content &&
            ReactHtmlParser(draftToHtml(JSON.parse(post.content)), {
              transform,
            })}
          <Typography align="right" variant="caption">
            {formattedDateTime()}
          </Typography>
        </CardContent>
        {post.isOnPostPage || (
          <Link style={{ textDecoration: "none" }} to={`/post/${post.id}`}>
            <Button
              style={{ width: "100%" }}
              color="primary"
              size="medium"
              variant="contained"
            >
              {isAuthenticated ? "view comments" : "Login to view comments"}
            </Button>
          </Link>
        )}
      </>
    </Card>
  );
};

export default Post;
