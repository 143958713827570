import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { CssBaseline } from "@material-ui/core";
import MenuDrawer from "./components/MenuDrawer";
import { API_ROOT } from "./utils/api-config";

const App: React.FC = () => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      const upsertUserData = async () => {
        try {
          const accessToken = await getAccessTokenSilently({
            audience: "http://localhost:3005/",
          });

          const postDetailsByIdUrl = `${API_ROOT}/users`;

          await fetch(postDetailsByIdUrl, {
            method: "post",
            body: JSON.stringify({
              email: user.email,
              fullname: user.username ? user.username : user.name,
              profilepic: user.picture,
            }),
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
          });
        } catch (e) {
          console.error(e.message);
        }
      };

      upsertUserData();
    }
  }, [isAuthenticated]);

  return (
    <>
      <CssBaseline />
      <MenuDrawer />
    </>
  );
};

export default App;
