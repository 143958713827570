import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import ArrowLeft from "@material-ui/icons/ArrowLeft";

export const Logout = () => {
  const { logout } = useAuth0();

  return (
    <ListItem onClick={() => logout()} button key="Logout">
      <ListItemIcon>
        <ArrowLeft />
      </ListItemIcon>
      <ListItemText primary="Logout" />
    </ListItem>
  );
};
