import React, { useEffect, useState } from "react";
import { Card, Button, CardContent } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import useFetch from "../hooks/useApiWithAuth";
import { useHistory } from "react-router-dom";
import { convertToRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: `${theme.spacing(4)}px ${theme.spacing(48)}px`,
      overflow: "unset",

      [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(1),
      },
      [theme.breakpoints.only("md")]: {
        margin: `${theme.spacing(4)}px ${theme.spacing(12)}px`,
      },
      [theme.breakpoints.only("lg")]: {
        margin: `${theme.spacing(4)}px ${theme.spacing(24)}px`,
      },
    },
    toolbarWrapper: {
      backgroundColor: theme.palette.background.paper,
      border: "none",
      color: theme.palette.background.default,
      zIndex: 10000,
    },
  })
);

type AddPostProps = {
  tagId: number;
};

export const AddPost: React.FC<{
  tagId: number;
}> = ({ tagId }: AddPostProps) => {
  const [postContent, updateContent] = useState(EditorState.createEmpty());

  const classes = useStyles();
  const history = useHistory();

  const {
    execute,
    loading: createPostLoading,
    response: createPostResponse,
  } = useFetch({
    url: "createpost",
    immediate: false,
  });

  useEffect(() => {
    if (!createPostLoading && createPostResponse.length > 0) {
      const [{ "LAST_INSERT_ID()": id }] = createPostResponse;
      history.push(`/post/${id}`);
    }
  }, [createPostLoading]);

  const handleSave = () => {
    execute({
      method: "post",
      body: JSON.stringify({
        content: JSON.stringify(convertToRaw(postContent.getCurrentContent())),
        tagId: tagId,
      }),
    });
  };

  function uploadImageCallBack(file: any) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open("POST", "https://api.imgur.com/3/image");
      xhr.setRequestHeader("Authorization", "Client-ID 15b130b9751cd77");
      const data = new FormData();
      data.append("image", file);
      xhr.send(data);
      xhr.addEventListener("load", () => {
        const response = JSON.parse(xhr.responseText);
        console.log(response);
        resolve(response);
      });
      xhr.addEventListener("error", () => {
        const error = JSON.parse(xhr.responseText);
        console.log(error);
        reject(error);
      });
    });
  }

  return (
    <Card className={classes.root}>
      <CardContent>
        <Editor
          onEditorStateChange={updateContent}
          toolbarClassName={classes.toolbarWrapper}
          toolbar={{
            options: ["image"],
            image: {
              uploadCallback: uploadImageCallBack,
            },
          }}
          placeholder="Type here..."
        />
      </CardContent>
      <Button
        color="primary"
        disabled={!postContent.getCurrentContent().hasText()}
        onClick={handleSave}
        variant="contained"
        style={{ width: "100%" }}
      >
        Post!
      </Button>
    </Card>
  );
};

export default AddPost;
