import React, { useEffect, useState } from "react";
import { Card, Button, CardContent } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import useFetch from "../hooks/useApiWithAuth";
import { convertToRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { useAuth0 } from "@auth0/auth0-react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginLeft: theme.spacing(52),
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(52),
      overflow: "unset",
      [theme.breakpoints.down("sm")]: {
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(2),
      },
      [theme.breakpoints.only("lg")]: {
        marginLeft: theme.spacing(26),
        marginRight: theme.spacing(26),
      },
      [theme.breakpoints.only("md")]: {
        marginLeft: theme.spacing(13),
        marginRight: theme.spacing(13),
      },
    },
    editorWrapper: {
      backgroundColor: theme.palette.background.paper,
      border: "none",
      color: theme.palette.background.default,
      overflow: "unset",
    },
  })
);

type AddCommentProps = {
  postId: number;
  onCommentAdd: () => void;
};

export const AddComment: React.FC<{
  postId: number;
  onCommentAdd: () => void;
}> = ({ postId, onCommentAdd }: AddCommentProps) => {
  const classes = useStyles();

  const { user } = useAuth0();

  const [commentContent, updateContent] = useState(EditorState.createEmpty());
  const [users, updateUsersInThread] = useState([]);

  const { execute, loading } = useFetch({
    url: "comments",
    immediate: false,
  });

  const { execute: postNotification } = useFetch({
    url: "createnotification",
    immediate: false,
  });

  const { response, loading: loadingUsers } = useFetch({
    url: `getUsersInThread?postId=${postId}`,
  });

  useEffect(() => {
    if (!loading) {
      onCommentAdd();
    }
  }, [loading]);

  useEffect(() => {
    if (!loading) {
      updateUsersInThread(
        response.reduce((prevValue: any, currValue: any, idx) => {
          const name = currValue.email.replace(/\s+/g, "-").toLowerCase();
          return [
            ...prevValue,
            {
              text: currValue.fullname,
              value: currValue.fullname,
              url: `/profile/${name}`,
              id: currValue.id,
            },
          ];
        }, [])
      );
    }
  }, [loadingUsers]);

  const handleContentChange = (state: EditorState) => {
    updateContent(state);
  };

  function uploadImageCallBack(file: any) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open("POST", "https://api.imgur.com/3/image");
      xhr.setRequestHeader("Authorization", "Client-ID 15b130b9751cd77");
      const data = new FormData();
      data.append("image", file);
      xhr.send(data);
      xhr.addEventListener("load", () => {
        const response = JSON.parse(xhr.responseText);
        console.log(response);
        resolve(response);
      });
      xhr.addEventListener("error", () => {
        const error = JSON.parse(xhr.responseText);
        console.log(error);
        reject(error);
      });
    });
  }

  const handleSave = () => {
    const rawContent = convertToRaw(commentContent.getCurrentContent());
    rawContent.blocks.map(({ text }) => {
      users.map(({ value, id }) => {
        if (text.includes(value)) {
          postNotification({
            method: "post",
            body: JSON.stringify({
              userId: id,
              fullname: user.username ? user.username : user.name,
              link: window.location.href,
            }),
          });
        }
      });
    });
    updateContent(EditorState.createEmpty());
    execute({
      method: "post",
      body: JSON.stringify({
        content: JSON.stringify(rawContent),
        postId: postId,
      }),
    });
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <Editor
          editorState={commentContent}
          toolbarClassName={classes.editorWrapper}
          editorClassName={classes.editorWrapper}
          wrapperClassName={classes.editorWrapper}
          toolbar={{
            options: ["image"],
            image: {
              uploadCallback: uploadImageCallBack,
            },
          }}
          onEditorStateChange={handleContentChange}
          mention={{
            separator: " ",
            trigger: "@",
            suggestions: users,
          }}
          placeholder="Type here..."
        />
      </CardContent>
      <Button
        color="primary"
        disabled={!commentContent.getCurrentContent().hasText()}
        onClick={handleSave}
        variant="contained"
        style={{ width: "100%" }}
      >
        Post!
      </Button>
    </Card>
  );
};

export default AddComment;
