import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Avatar,
  CircularProgress,
  createStyles,
  makeStyles,
  Menu,
  MenuItem,
} from "@material-ui/core";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Badge from "@material-ui/core/Badge/Badge";
import useFetch from "../hooks/useApiWithAuth";

const useStyles = makeStyles(() =>
  createStyles({
    flex: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      height: "105px",
    },
  })
);

export const MenuProfileWidget = () => {
  const { user, isAuthenticated } = useAuth0();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { execute, response, loading } = useFetch({
    url: "getnotifications",
    useAuth: true,
    immediate: false,
  });

  const { execute: deletenotification } = useFetch({
    url: "deletenotification",
    immediate: false,
  });

  useEffect(() => {
    if (isAuthenticated) {
      execute();
    }
  }, [isAuthenticated]);

  return isAuthenticated ? (
    <>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {loading && <CircularProgress />}
        {response.length === 0 && !loading && (
          <MenuItem onClick={handleClose}>No Notifications</MenuItem>
        )}
        {response &&
          !loading &&
          response.map(
            (notification: { id: number; link: string; fullname: string }) => {
              return (
                <a
                  onClick={() =>
                    deletenotification({
                      method: "post",
                      body: JSON.stringify({
                        notificationId: notification.id,
                      }),
                    })
                  }
                  href={notification.link}
                  key={notification.id}
                >
                  <MenuItem onClick={handleClose}>
                    {user.username ? user.username : user.name} mentioned you
                  </MenuItem>
                </a>
              );
            }
          )}
      </Menu>
      <div className={classes.flex}>
        <Avatar src={user.picture} />
        <h4>{user.username ? user.username : user.name}</h4>
        <Badge badgeContent={response.length}>
          <NotificationsIcon onClick={handleClick} />
        </Badge>
      </div>
    </>
  ) : (
    <div className={classes.flex} />
  );
};
