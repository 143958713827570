import React, { ComponentType } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Route, Switch } from "react-router-dom";
import Home from "./pages/home";
import Post from "./pages/post";
import TagFeed from "./pages/tag-feed";
import Profile from "./pages/user-profile";

type ProtectedRouteProps = {
  component: ComponentType<any>;
  exact?: boolean;
  path: string;
};

const ProtectedRoute = ({ component, ...args }: ProtectedRouteProps) => (
  <Route component={withAuthenticationRequired(component)} {...args} />
);

export const PageRouter = () => {
  return (
    <Switch>
      <ProtectedRoute exact path="/profile/:email" component={Profile} />
      <ProtectedRoute path="/post/:id/:commentId?" component={Post} />
      <Route exact path="/tag/:id/:page?" component={TagFeed} />
      <Route exact path="/" component={Home} />
    </Switch>
  );
};

export default PageRouter;
