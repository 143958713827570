import React from "react";
import Post from "../components/Post";
import { PostType, TagType } from "../utils/types";
import useFetch from "../hooks/useApiWithAuth";
import PostsSkeleton from "../components/skeleton/PostsSkeleton";
import AddPost from "../components/AddPost";
import {
  Typography,
  Card,
  Button,
  TextField,
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core";
import { useAuth0 } from "@auth0/auth0-react";
import { useDebounce } from "@react-hook/debounce";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerCard: {
      margin: `${theme.spacing(2)}px ${theme.spacing(8)}px`,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(1),
        flexDirection: "column",
      },
    },
  })
);

export const TagFeed = ({
  match: {
    params: { id, page = 0 },
  },
}: any) => {
  const { isAuthenticated } = useAuth0();
  const [searchText, updateSearchText] = useDebounce("", 1000);
  const classes = useStyles();

  const { response: posts, loading } = useFetch({
    url: `postbytag?tagId=${id}&page=${page}&strMatch=${`%${searchText}%`}`,
    useAuth: false,
  });

  const { response: tags, loading: tagLoading } = useFetch({
    url: "tags",
    useAuth: false,
  });

  const getTag = () => {
    if (!tagLoading) {
      if (
        tags.filter((tag: TagType) => {
          return tag.id.toString() === id;
        }).length > 0
      ) {
        const { tag } = tags.filter((tag: TagType) => {
          return tag.id.toString() === id;
        })[0] as TagType;
        return tag;
      }
      return "";
    }
  };

  return (
    <>
      <div className={classes.headerCard}>
        <Typography align="left" variant="h3">
          {getTag()}
        </Typography>
        <TextField
          style={{ margin: "8px" }}
          placeholder="Search Posts"
          variant="outlined"
          onChange={(event) => updateSearchText(event.target.value)}
        />
      </div>
      {isAuthenticated ? (
        <AddPost tagId={id} />
      ) : (
        <Card style={{ margin: "8px" }}>
          <></>
        </Card>
      )}
      {loading ? (
        <PostsSkeleton />
      ) : (
        posts
          .slice()
          .sort(
            (a: PostType, b: PostType) =>
              Date.parse(a.timestamp) - Date.parse(b.timestamp)
          )
          .reverse()
          .map((post: PostType) => <Post key={post.id} {...post} />)
      )}
      {posts.length > 0 || loading}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          marginBottom: "16px",
        }}
      >
        <Button
          onClick={() => {
            window.location.replace(`/tag/${id}/${Number.parseInt(page) - 1}`);
          }}
          disabled={page == 0}
          color="primary"
        >
          Previous Page
        </Button>
        <Button
          onClick={() => {
            window.location.replace(`/tag/${id}/${Number.parseInt(page) + 1}`);
          }}
          disabled={posts.length < 5}
          color="primary"
        >
          Next Page
        </Button>
      </div>
    </>
  );
};

export default TagFeed;
