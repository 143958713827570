import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Menu,
  MenuItem,
  Link,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { CommentType } from "../utils/types";
import useFetch from "../hooks/useApiWithAuth";
import ReactHtmlParser from "react-html-parser";
import draftToHtml from "draftjs-to-html";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    comment: {
      marginBottom: theme.spacing(4),
      marginLeft: theme.spacing(52),
      marginRight: theme.spacing(52),

      [theme.breakpoints.down("sm")]: {
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(2),
      },
      [theme.breakpoints.only("lg")]: {
        marginLeft: theme.spacing(26),
        marginRight: theme.spacing(26),
      },
      [theme.breakpoints.only("md")]: {
        marginLeft: theme.spacing(13),
        marginRight: theme.spacing(13),
      },
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
    avatar: {
      backgroundColor: theme.palette.primary.dark,
    },
  })
);

const Comment = (comment: CommentType) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { user } = useAuth0();
  const { execute, loading } = useFetch({
    url: "deletecomment",
    immediate: false,
  });

  const formattedDateTime = () => {
    return new Date(comment.timestamp).toLocaleString();
  };

  useEffect(() => {
    if (!loading && comment.onCommentDelete) {
      comment.onCommentDelete();
    }
  }, [loading]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleReport = () => {
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card key={comment.id} className={classes.comment}>
      <CardHeader
        avatar={
          <Avatar
            aria-label="recipe"
            src={comment.profilepic}
            className={classes.avatar}
          />
        }
        action={
          <div>
            <IconButton onClick={handleClick} aria-label="settings">
              <MoreVertIcon />
            </IconButton>
          </div>
        }
        title={comment.fullname}
      />
      <CardContent>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {user.email !== comment.email ? (
            <Link underline="none" href="mailto:admin@thinkfloat.net">
              <MenuItem onClick={handleReport}>Report</MenuItem>
            </Link>
          ) : (
            <MenuItem
              onClick={() =>
                execute({
                  method: "post",
                  body: JSON.stringify({
                    commentId: comment.id,
                  }),
                })
              }
            >
              Delete
            </MenuItem>
          )}
        </Menu>
        {comment.content &&
          ReactHtmlParser(draftToHtml(JSON.parse(comment.content)))}
        <Typography align="right" variant="caption">
          {formattedDateTime()}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default Comment;
